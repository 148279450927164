<template>
  <b-modal
      size="lg"
      :title="item.title"
      title-class="text-lg p-2 text-center text-white"
      :header-bg-variant="item.color ? item.color : 'purple'"
      id="servicePackageEntryModal"
      centered
      cancel-title="Закрыть"
      :ok-title="`Оплатить ${item.price} ₽`"
      :ok-disabled="!canBePaid"
      button-size="xl"
      @ok="enroll"
  >

    <div class="tip my-2 p-2 rounded shadow-sm mt-3 text-sm">
      <b> Записаться на пакет услуг? </b>
      Для записи на пакет услуг, его нужно оплатить онлайн.
      После оплаты с Вами свяжется оператор, чтобы уточнить секции,
      на которые Вы хотели бы записаться.
    </div>


    <div class="mx-2 mb-3">{{ item.description }}</div>

    <b-list-group flush class="mb--4 mt--3">
      <b-list-group-item v-for="service in item.services" :key="service.id">
        <b-row>
          <b-col cols="1" class="m-3"><h2>
              <i :class="`${service.type.icon} text-${service.type.color} mt-1 text-xl`" />
          </h2></b-col>
          <b-col>

            <b-row>
              <b-col lg="8" cols="12">
                <b-badge :class="`bg-${service.type.color} text-white`">
                  <b class="text-wrap">
                    {{ service.type.fdv }}
                  </b>
                </b-badge><br>
                <div class="ml-1 mr-5">{{ service.title }}</div>
                <div class="ml-1 mr-5 text-muted text-xs" v-if="service.begda && service.endda">
                  {{ service.interval_formatted }}
                </div>
            </b-col>
            <b-col lg="4" cols="12">
              <div v-if="service.sections.length > 0" class="ml-1 mr-5">
                <l-select
                    size="sm"
                    class="w-lg-50"
                    v-model="entries[service.id]"
                    :options="service.sections"
                    placeholder-text="Выберите секцию"
                    :allow-empty="false"
                />
              </div>
            </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import paymentMixin from "../../../mixins/payment-mixin";
import ServicePackageResource from "../../../resources/service-package";

export default {
  name: "ServicePackageEntryModal",
  mixins: [
    paymentMixin
  ],
  data() {
    return {
      entries: {},
      canBePaid: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  watch: {
    item: {
      handler() {
        this.entries = {};
      },
      deep: true,
    },
    entries: {
      handler() {
        const servicesWithSections = this.item.services.filter(
            service => service.sections.length > 0
        );
        if (!servicesWithSections.length) return this.canBePaid = true;
        const chosenSections = Object.keys(this.entries);
        this.canBePaid = chosenSections.length && chosenSections.length === servicesWithSections.length;
      },
      deep: true,
    }
  },
  methods: {
    async enroll() {
      const entry = await ServicePackageResource.enroll(this.item.id, this.entries);
      await this.pay(entry);
    },
  },
}
</script>

<style scoped>
.tip {
  background: var(--secondary);
  border: 0.1rem solid rgba(0, 0, 0, 0.03);
}
</style>